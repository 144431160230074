/*
 * File: general.utilities.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: January 24, 2025 at 5:18 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Constants
const {
	OBFUSCATE_REGEX,
	OBFUSCATE_TEXT_REPLACE,
	AVAILABLE_LOCALES
} = require('../constants/general.constants');


/**
 * Utilities
 */

const formatTextRedactions = (content = '') => content.replace(OBFUSCATE_REGEX, (str) => {
	const element = str.slice(2, -2);
	if (element === '') {
		return `(${OBFUSCATE_TEXT_REPLACE})`;
	}
	return `(${element})`;
});

// Helper to create locale-free base url
const reduceToBaseURL = (p) => {
	let path = p;
	if (path) {
		AVAILABLE_LOCALES.forEach((locale) => {
			const testArray = path.split(locale);
			if (testArray.length > 1 && testArray[0] === '/') {
				const testPath = testArray[1];
				if ((testPath.length === 0 || testPath.charAt(0) === '/' || testPath.charAt(0) === '?')) {
					path = path.replace(locale, '').replace('//', '/');
				}
			}
		});
	}
	return path;
};


/**
 * Exports
 */

module.exports = {
	formatTextRedactions,
	reduceToBaseURL
};
